<template>
  <v-app>
    <div class="lds-roller" v-if="isLoading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="content">
      <Navigation class="fixed-nav"></Navigation>
      <div class="row vcenter layout">
        <div class="left col-lg-6 offset-lg-0 col-md-12 col-sm-12">
          <div class="row centered contactus">
            <div
              class="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-8 offset-sm-2"
            >
              <div class="title">
                <h1>Contactez-nous</h1>
              </div>
            </div>
          </div>
          <div class="row centered">
            <div
              class="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-8 offset-sm-2"
            >
              <div class="subtitle">
                <h5>
                  Une question ? Un devis ? Nous nous engageons à vous répondre
                  dans les plus brefs délais.
                  <br />
                  <br />Notre boutique étant en développement, n'hésitez pas à
                  nous contacter pour effectuer une commande.
                </h5>
              </div>
            </div>
          </div>
          <div class="row centered details">
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <p style="margin-bottom: 0px">
                <i class="icon fa fa-phone"></i>
                <br />
                <a href="tel:0471 68 99 66">0471 68 99 66</a>
              </p>
              <span style="font-size: 14px; font-weight: bold">ou</span>
              <p>
                <i class="icon fa fa-phone"></i>
                <br />
                <a href="tel:0479 53 58 43">0479 53 58 43</a>
              </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <p>
                <i class="icon fa fa-map-pin"></i>
                <br />
                <a
                  target="_blank"
                  href="https://www.google.com/maps?q=Clos+des+princes+21,+7070+Le+Roeulx"
                >
                  Clos des princes 21
                  <br />7070 Le Roeulx <br />Belgique
                </a>
              </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <p>
                <i class="icon fa fa-envelope"></i>
                <br />
                <a target="_blank" href="mailto:info@coffee-factory.be"
                  >info@coffee-factory.be</a
                >
              </p>
            </div>
          </div>
        </div>
        <div
          class="right col-lg-6 offset-lg-0 col-md-10 offset-md-1 col-sm-10 offset-sm-1 col-10 offset-1"
        >
          <div
            class="col-lg-10 offset-lg-1 col-md-12 offset-md-0 col-sm-12 offset-sm-0 col-12 offset-0 box"
          >
            <v-form ref="form">
              <div class="row">
                <div
                  class="col-lg-5 offset-lg-1 col-md-5 offset-md-1 col-sm-5 offset-sm-1 col-10 offset-1"
                >
                  <v-text-field
                    dark
                    v-model="name"
                    label="Nom*"
                    required
                    prepend-inner-icon="fa-user"
                  ></v-text-field>
                </div>
                <div
                  class="col-lg-5 offset-lg-0 col-md-5 offset-md-0 col-sm-5 offset-sm-0 col-10 offset-1"
                >
                  <v-text-field
                    dark
                    v-model="company"
                    prepend-inner-icon="fa-building"
                    label="Société"
                  ></v-text-field>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-lg-5 offset-lg-1 col-md-5 offset-md-1 col-sm-5 offset-sm-1 col-10 offset-1"
                >
                  <v-text-field
                    dark
                    v-model="mail"
                    label="E-mail*"
                    type="email"
                    prepend-inner-icon="fa-envelope"
                    required
                    :rules="emailRules"
                  ></v-text-field>
                </div>
                <div
                  class="col-lg-5 offset-lg-0 col-md-5 offset-md-0 col-sm-5 offset-sm-0 col-10 offset-1"
                >
                  <v-text-field
                    dark
                    v-model="phone"
                    label="Téléphone*"
                    prepend-inner-icon="fa-phone"
                    type="phone"
                    required
                  ></v-text-field>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-lg-8 offset-lg-1 col-md-8 offset-md-1 col-sm-8 offset-sm-1 col-10 offset-1"
                >
                  <v-textarea
                    required
                    dark
                    no-resize
                    label="Message*"
                    v-model="message"
                    prepend-inner-icon="fa-pencil"
                    value
                  ></v-textarea>
                </div>
                <div
                  class="col-lg-1 offset-1 vcenter col-md-1 offset-md-1 col-sm-1 offset-sm-1 col-6 offset-3 text-center"
                >
                  <transition name="fade">
                    <a
                      v-if="
                        this.name &&
                        this.phone &&
                        this.isValidEmail &&
                        this.message
                      "
                      id="send-btn"
                      title="Envoyer"
                      @click="sendEmail"
                    >
                      <div id="send-mobile">
                        <span style="font-size: 24px">
                          Envoyer
                          <i class="fa fa-paper-plane-o"></i>
                        </span>
                      </div>
                      <div id="send">
                        <i class="fa fa-paper-plane-o"></i>
                      </div>
                    </a>
                  </transition>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <template>
      <form class="contact-form" id="form" @submit.prevent="sendEmail">
        <label>Nom</label>
        <input type="text" name="user_name" v-model="name" />
        <label>Société</label>
        <input type="text" name="user_company" v-model="company" />
        <label>Email</label>
        <input type="email" name="user_email" v-model="mail" />
        <label>Téléphone</label>
        <input type="phone" name="user_phone" v-model="phone" />
        <label>Message</label>
        <textarea name="user_message" v-model="message"></textarea>
        <input type="submit" value="Send" />
      </form>
    </template>
  </v-app>
</template>

<script>
import Navigation from "../components/Navigation";
import emailjs from "emailjs-com";

export default {
  name: "Contact",
  components: {
    Navigation: Navigation,
  },
  data() {
    return {
      name: "",
      company: "",
      phone: "",
      mail: "",
      message: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      isLoading: false,
    };
  },
  created() {
    document.title = "Contact - The Coffee Factory";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Une question ? Un devis ? Nous nous engageons à vous répondre dans les plus brefs délais. Notre boutique étant en développement, n'hésitez pas à nous contacter pour effectuer une commande."
      );
  },
  methods: {
    sendEmail: function () {
      this.isLoading = true;
      var self = this;
      emailjs
        .sendForm(
          "smtp_server",
          "the_coffee_factory",
          "#form",
          "user_s4ycPiW1xxP9cSEOEVF92"
        )
        .then(
          () => {
            self.isLoading = false;
            let title = "Message envoyé";
            let message =
              "Nous avons bien reçu votre message. Nous reviendrons vers vous dans les plus brefs délais. Merci !";
            self.alert(title, message, "success");
            self.clearForm();
          },
          () => {
            self.isLoading = false;
            let title = "Erreur";
            let message =
              "Une erreur est survenue lors de l'envoi de votre message. Vérifiez votre connexion internet et réessayez. Si le problème persiste, veuillez nous contacter par mail ou par téléphone. Merci !";
            self.alert(title, message, "error");
          }
        );
    },

    alert: function (title, message, type) {
      this.$swal(title, message, type);
    },

    clearForm: function () {
      this.name = "";
      this.company = "";
      this.phone = "";
      this.mail = "";
      this.message = "";
    },
  },
  computed: {
    isValidEmail() {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.mail);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");

body {
  font-family: "Quicksand", sans-serif;
}

v-app {
  background-color: transparent;
}

.contact-form {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.layout {
  height: 100%;
  padding-top: 92px;
  padding-bottom: 92px;
}

p {
  text-align: center;
}

.content {
  background-image: url(../assets/contact-1440.jpg),
    url(../assets/contact-1024.jpg);
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  background-position: center center;
  position: relative;
  font-family: Quicksand;
  width: 100%;
  overflow: auto;
  height: auto;
}

@media screen and (min-width: 1025px) {
  .content {
    background-image: url(../assets/contact-1920.jpg),
      url(../assets/contact-1440.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
  }
}

@media screen and (min-width: 1441px) {
  .content {
    background-image: url(../assets/contact-3840.jpg),
      url(../assets/contact-1920.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
  }
}

.fixed-nav {
  position: fixed !important;
  top: 0;
  width: 100%;
  background-color: black;
  animation-name: navbarColor;
  animation-duration: 0.3s;
  height: 92px;
  font-family: "Poppins", sans-serif;
}

.right {
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 24px;
  font-weight: 500;
  font-family: "Quicksand", sans-serif !important;
}

.subtitle {
  font-size: 20px;
  padding-top: 5%;
}

img {
  width: 100%;
  height: auto;
  border-radius: 70%;
  display: block;
}

#send-btn {
  text-decoration: none;
  font-size: 30px;
  color: white;
  width: 100%;
}

.box {
  height: 100%;
  background-color: #92561d;
  box-shadow: 15px 15px 100px #92561d;
  border-radius: 50px;
  color: white;
}

.v-input {
  font-size: 14px;
}

.vcenter {
  display: flex;
  align-items: center;
  text-align: left;
}

.centered {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

p a,
p {
  color: black;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  text-decoration: none;
}

p a:hover,
p:hover {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  text-decoration: none;
}

#send {
  display: block;
}

#send-mobile {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 786px) {
  .details {
    padding-top: 0;
    display: flex;
    align-items: center;
  }

  .contactus {
    padding-top: 5%;
  }
}

@media only screen and (max-width: 576px) {
  #send-mobile {
    display: block;
  }

  #send {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .content {
    background-image: url(../assets/contact-rotate-1024.jpg),
      url(../assets/contact-rotate-840.jpg);
  }
}

@media only screen and (max-width: 500px) {
  .content {
    background-image: url(../assets/contact-rotate-840.jpg),
      url(../assets/contact-rotate-360.jpg);
  }
}

@media only screen and (min-width: 768px) {
  .content {
    height: 100vh;
  }
}

//Loading spin
.lds-roller {
  position: fixed;
  z-index: 999;
  width: 80px;
  height: 80px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>