<template>
  <div class="content">
    <div class="row banner vcenter">
      <div class="col-12">
        <br />
        <h2 class="banner-title">Nos marques</h2>
        <br />
      </div>
    </div>

    <div class="row vcenter">
      <div class="col margin-bottom">
        <div class="brands__wrap">
          <h6 class="brands__brand">Caffè Vergnano</h6>
          <br />
          <a target="_blank" href="http://www.caffevergnano.fr/">
            <img
              class="brands__logo"
              src="../assets/logo-vergnano.png"
              @mouseover="setSelected($event)"
            />
          </a>
        </div>
      </div>
      <div class="col margin-bottom">
        <div class="brands__wrap">
          <h6 class="brands__brand">Vibiemme</h6>
          <br />
          <a target="_blank" href="https://www.vbmespresso.com/">
            <img
              class="brands__logo"
              src="../assets/logo-vbm.png"
              @mouseover="setSelected($event)"
            />
          </a>
        </div>
      </div>
      <div class="col margin-bottom">
        <div class="brands__wrap">
          <h6 class="brands__brand">Nutroma</h6>
          <br />
          <a target="_blank" href="https://www.nutroma.be/fr/">
            <img
              class="brands__logo"
              src="../assets/logo-nutroma.png"
              @mouseover="setSelected($event)"
            />
          </a>
        </div>
      </div>
      <div class="col margin-bottom">
        <div class="brands__wrap">
          <h6 class="brands__brand">Lattiz</h6>
          <br />
          <a target="_blank" href="https://www.lattiz.com/fr-be">
            <img
              class="brands__logo"
              src="../assets/logo-lattiz.png"
              @mouseover="setSelected($event)"
            />
          </a>
        </div>
      </div>

      <div class="col margin-bottom">
        <div class="brands__wrap">
          <h6 class="brands__brand">L'atelier chocolat</h6>
          <br />
          <a target="_blank" href="http://www.atelierchocolat.be/fr/">
            <img
              class="brands__logo"
              src="../assets/logo-atelier.png"
              @mouseover="setSelected($event)"
            />
          </a>
        </div>
      </div>
      <div class="col margin-bottom">
        <div class="brands__wrap">
          <h6 class="brands__brand">Saeco</h6>
          <br />
          <a target="_blank" href="https://www.saecoprofessional.fr/">
            <img
              class="brands__logo"
              src="../assets/logo-saeco.png"
              @mouseover="setSelected($event)"
            />
          </a>
        </div>
      </div>
      <div class="col margin-bottom">
        <div class="brands__wrap">
          <h6 class="brands__brand">Necta</h6>
          <br />
          <a target="_blank" href="https://necta.evocagroup.com/fr/home">
            <img
              class="brands__logo"
              src="../assets/logo-necta.png"
              @mouseover="setSelected($event)"
            />
          </a>
        </div>
      </div>
      <div class="col margin-bottom">
        <div class="brands__wrap">
          <h6 class="brands__brand">Elektra</h6>
          <br />
          <a target="_blank" href="https://www.elektrasrl.com/en/">
            <img
              class="brands__logo"
              src="../assets/logo-elektra.png"
              @mouseover="setSelected($event)"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Brands",

  methods: {
    /**
     * Display brands on hover
     */
    setSelected: function (event) {
      event.srcElement.parentElement.parentElement.classList.add("selected");
      let brands = document.getElementsByClassName("brands__wrap");

      for (let i = 0; i < brands.length; i++) {
        if (
          brands[i].children[0].innerHTML !=
          event.srcElement.parentElement.parentElement.children[0].innerHTML
        ) {
          brands[i].classList.remove("selected");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  color: white;
  padding-top: 11vh;
  padding-bottom: 11vh;
  text-shadow: 1px 1px 2px #111;
  background-color: transparent;
  background-image: url(../assets/banner-brands-840.jpg),
    url(../assets/banner-brands-360.jpg);
  background-repeat: no-repeat, no-repeat;
  background-position: center center;
  background-size: cover cover;
  border-top: rgb(146, 86, 29) 2px solid;
  border-bottom: rgb(146, 86, 29) 2px solid;
}

.margin-bottom {
  margin-bottom: 30px;
}

.vcenter {
  display: flex;
  align-items: center;
  text-align: center;
}

.banner-title {
  font-weight: bold;
  margin-bottom: 0;
}

.brands__logo {
  max-width: 100px;
  height: auto;
  transition: 0.4s;
  margin-top: auto;
  margin-bottom: auto;
}

.brands__brand {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

.selected .brands__logo {
  -ms-transform: scale(1.15);
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
  -moz-transform: scale(1.15);
  -o-transform: scale(1.15);
}

.selected .brands__brand {
  visibility: visible;
  opacity: 1;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.col {
  padding-left: 15px;
  padding-right: 15px;
}

.row .col {
  flex: 0 0 25%;
  max-width: 0 0 25% !important;
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
  .row .col {
    flex: 0 0 25%;
    max-width: 0 0 25% !important;
  }
}

@media only screen and (max-width: 767px) {
  .row .col {
    flex: 0 0 33%;
    max-width: 0 0 33% !important;
  }
}

@media only screen and (max-width: 499px) {
  .row .col {
    flex: 0 0 50%;
    max-width: 0 0 50% !important;
  }
}

@media screen and (min-width: 500px) {
  .banner {
    background-image: url(../assets/banner-brands-1024.jpg),
      url(../assets/banner-brands-840.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}

@media screen and (min-width: 840px) {
  .banner {
    background-image: url(../assets/banner-brands-1440.jpg),
      url(../assets/banner-brands-1024.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}

@media screen and (min-width: 1025px) {
  .banner {
    background-image: url(../assets/banner-brands-1920.jpg),
      url(../assets/banner-brands-1440.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}

@media screen and (min-width: 1441px) {
  .banner {
    background-image: url(../assets/banner-brands-3840.jpg),
      url(../assets/banner-brands-1920.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}
</style>