<template>
  <div class="content">
    <section id="home">
      <Home></Home>
    </section>

    <section id="aboutus">
      <Navigation></Navigation>

      <!-- Scrolling progress -->
      <div class="progress-container" id="progress-c">
        <div class="progress-bar" id="progress"></div>
      </div>

      <Entreprise></Entreprise>
    </section>

    <section id="solutions">
      <Solution></Solution>
    </section>

    <section id="vergnano">
      <Vergnano></Vergnano>
    </section>

    <section id="brands">
      <Brands></Brands>
    </section>

    <section id="quote">
      <blockquote>A good day begins after coffee.</blockquote>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

import Navigation from "../components/Navigation";
import Home from "../components/Home";
import Entreprise from "../components/Entreprise";
import Vergnano from "../components/Vergnano";
import Solution from "../components/Solution";
import Brands from "../components/Brands";
import Footer from "../components/Footer";

export default {
  name: "App",

  components: {
    Navigation: Navigation,
    Home: Home,
    Entreprise: Entreprise,
    Vergnano: Vergnano,
    Solution: Solution,
    Brands: Brands,
    Footer: Footer,
  },

  created() {
    document.title = "The Coffee Factory";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Entreprise familiale depuis 2011, The Coffee Factory propose aux particuliers, mais également aux entreprises, aux restaurants et aux hôtels, le meilleur du café à Bruxelles et en Région wallonne."
      );
    AOS.init();
    window.addEventListener("scroll", this.updateProgressBar);
  },

  methods: {
    /**
     * Update progress bar according to scrolling position
     */
    updateProgressBar: function () {
      if (this.$router.currentRoute.name != "contact") {
        var scrollTop =
          document.documentElement["scrollTop"] || document.body["scrollTop"];
        if (scrollTop > document.documentElement.clientHeight) {
          var scrollBottom =
            (document.documentElement["scrollHeight"] ||
              document.body["scrollHeight"]) -
            document.documentElement.clientHeight;
          let scrollPercent =
            ((scrollTop - document.documentElement.clientHeight) /
              (scrollBottom - document.documentElement.clientHeight)) *
              100 +
            "%";
          document
            .getElementById("progress")
            .style.setProperty("--scroll", scrollPercent);
        } else {
          document.getElementById("progress").style.setProperty("--scroll", 0);
          document
            .getElementById("progress-c")
            .style.setProperty("background", "transparent");
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Special+Elite");
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f5f1e9;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

#home {
  height: 100vh;
}

#aboutus {
  padding-bottom: 5vh;
  background-color: #f5f1e9;
  color: black;
}

#vergnano {
  padding-bottom: 5vh;
  background-color: #f5f1e9;
  color: black;
}

#solutions {
  background-color: #2a1506;
  padding-bottom: 5vh;
  color: white;
}

#brands {
  background-color: #2a1506;
  padding-bottom: 5vh;
  color: white;
}

#quote {
  background-color: #f5f1e9;
  padding-top: 4vh;
  padding-bottom: 4vh;
  color: black;
  font-family: "Special Elite", cursive;
  border-top: rgb(146, 86, 29) 2px solid;
}

@media only screen and (min-width: 1250px) {
  blockquote {
    font-size: 4rem !important;
  }
}

@media only screen and (min-width: 1024px) {
  blockquote {
    font-size: 3.5rem !important;
  }

  #quote {
    padding-top: 2vh;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  blockquote {
    font-size: 2.5rem !important;
  }

  #quote {
    padding-top: 2vh;
  }
}

@media only screen and (max-width: 767px) {
  blockquote {
    font-size: 1.5rem !important;
  }

  blockquote:before,
  blockquote:after {
    font-size: 4rem !important;
  }

  blockquote:before {
    top: 0rem !important;
  }

  blockquote:after {
    bottom: -3rem !important;
  }
}

blockquote {
  font-weight: 300;
  font-size: 3rem;
  line-height: 1.4;
  position: relative;
  margin: 0;
  padding: 0;
  text-align: center;
}

blockquote:before,
blockquote:after {
  position: relative;
  color: black;
  font-size: 8rem;
  width: 4rem;
  height: 4rem;
}

blockquote:before {
  content: "“";
  top: 1rem;
  text-align: center;
}

blockquote:after {
  content: "”";
  bottom: -6rem;
}

.progress-container {
  top: 0;
  position: fixed;
  width: 100%;
  background-color: black;
  opacity: 0.9;
  z-index: 100;
}

.progress-bar {
  --scroll: 0%;
  background: linear-gradient(
    to right,
    rgb(146, 86, 29) var(--scroll),
    transparent 0
  );
  width: 100%;
  z-index: 100;
}

@media only screen and (min-width: 851px) {
  .progress-bar {
    position: fixed;
    height: 5px;
    top: 92px !important;
  }
}

@media only screen and (max-width: 850px) {
  .progress-bar {
    position: fixed;
    height: 5px;
    top: 60px !important;
  }
}
</style>