<template>
  <div class="content">
    <div class="row banner vcenter">
      <div class="col-12">
        <h2 class="banner-title">Caffè Vergnano</h2>
        <br />
        <h3 class="banner-subtitle">L'art du café depuis 1882 !</h3>
      </div>
    </div>
    <div class="row row-intro vcenter">
      <div
        class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-10 offset-sm-1 col-10 offset-1"
      >
        <p data-aos="fade-up" data-aos-duration="1000" class="introduction">
          Fondée en Italie par Domenico Vergnano en 1882, Caffè Vergnano fut à
          l’origine une petite épicerie de Chieri en Italie. Aujourd’hui, elle
          est
          <strong> la sixième entreprise italienne </strong>
          dans le canal de la grande distribution et est présente dans
          <strong>19 pays du monde.</strong>
        </p>
      </div>
    </div>

    <div class="row row-middle vcenter">
      <div
        class="col-lg-4 offset-lg-0 col-md-4 offset-md-0 col-sm-10 offset-sm-1"
      >
        <img
          data-aos="zoom-in"
          data-aos-duration="1000"
          class="image image-1882"
          src="../assets/vergnano-1882.jpg"
        />
      </div>

      <div
        class="col-lg-8 offset-lg-0 col-md-8 offset-md-0 col-sm-10 offset-sm-1"
      >
        <h1 class="stat-title">
          Caffè Vergnano en chiffres
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="0 0 50 50"
            style="fill: #000000"
          >
            <path
              d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"
            />
          </svg>
        </h1>

        <div class="row vcenter stat-line1">
          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            class="col-4 line-height-null"
          >
            <span class="small-stat">Exporte dans + de</span>
            <h2 class="no-margin">90 pays</h2>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1500"
            class="col-4 line-height-null"
          >
            <h2 class="no-margin">128 boutiques</h2>
            <span class="small-stat">à travers le monde</span>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            class="col-4 line-height-null"
          >
            <span class="small-stat">Une usine de</span>
            <h2 class="no-margin">
              13.500 m
              <sup>2</sup>
            </h2>
            <span class="small-stat">à Santena</span>
          </div>
        </div>

        <div class="row vcenter">
          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            class="col-4 line-height-null"
          >
            <h2 class="no-margin">80+</h2>
            <span class="small-stat">références produits</span>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1500"
            class="col-4 line-height-null"
          >
            <h2 class="no-margin">6.200</h2>
            <span class="small-stat"
              >tonnes de café produites chaque année</span
            >
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            class="col-4 line-height-null"
          >
            <h2 class="no-margin">100</h2>
            <span class="small-stat">millions de capsules vendues</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-middle vcenter">
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        class="col-lg-6 offset-lg-1 col-md-6 offset-md-1 col-sm-6 offset-sm-1 col-xs-6 offset-xs-3"
      >
        <h3 class="title">Un travail traditionnel</h3>
        <br />
        <p class="col-12 text">
          Caffè Vergnano est une entreprise fidèle aux meilleures traditions
          tout en étant l’un des producteurs les plus dynamiques et les plus
          avant-gardistes.
          <br />
          <br />La qualité supérieure des mélanges est née d'une sélection
          méticuleuse de matières premières et d'un processus de production
          transmis de génération en génération.
          <br />
          <br />La torréfaction est le moment le plus délicat lors de la
          fabrication du café car la bonne concentration de goût et de saveur
          des grains dépend de la précision de cette procédure.
        </p>
      </div>
      <div
        class="col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-3 offset-sm-1 col-xs-6 offset-xs-3"
      >
        <img
          data-aos="zoom-in"
          data-aos-duration="1000"
          src="../assets/vergnano-tradition.jpg"
          class="img-fluid image image-tradition"
        />
      </div>
    </div>

    <div class="row row-middle roasting vcenter">
      <div
        class="col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-3 offset-sm-1 col-xs-6 offset-xs-3"
      >
        <img
          data-aos="zoom-in"
          data-aos-duration="1000"
          src="../assets/vergnano-roasting.jpg"
          class="img-fluid image image-roasting"
        />
      </div>
      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        class="col-lg-6 offset-lg-1 col-md-6 offset-md-1 col-sm-6 offset-sm-1 col-xs-6 offset-xs-3"
      >
        <h3 class="title">Une torréfaction lente</h3>
        <br />
        <p class="col-12 text">
          Les différentes variétés de café sont torréfiées séparément,
          garantissant le juste degré de cuisson.
          <br />
          <br />Comparé à une majorité des torréfactions actuelles qui
          travaillent avec la méthode industrielle « turbo », Caffè Vergnano
          utilise un système de torréfaction traditionnel de type « lent ».
          <br />
          <br />Chaque cycle dure de 18 à 22 minutes. Une fois ce dernier
          terminé, chaque lot torréfié est contrôlé personnellement afin de
          garantir au Caffè Vergnano une qualité constante tout au long du
          processus.
        </p>
      </div>
    </div>
    <div class="row row-middle vcenter">
      <div class="col-12 centered quote">
        'Caffè Vergnano, l'autentico espresso italiano dal 1882'
      </div>
    </div>

    <div class="col-12 cta-shop centered">
      Vous désirez goûter nos cafés ?
      <h6 class="cta-shop-btn" @click="goToContact">
        Visitez notre boutique en ligne
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "Vergnano",
  methods: {
    goToContact() {
      this.$router.push("contact");
    },
  },
};
</script>

<style lang="scss" scoped>
.vcenter {
  display: flex;
  align-items: center;
  text-align: center;
}

.banner {
  padding-top: 11vh;
  padding-bottom: 11vh;
  text-shadow: 1px 1px 2px #111;
  color: white;
  background-color: transparent;
  background-image: url(../assets/banner-vergnano.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-top: rgb(146, 86, 29) 2px solid;
  border-bottom: rgb(146, 86, 29) 2px solid;
}

.banner-title {
  font-size: 32px;
  font-weight: bold;
}

.banner-subtitle {
  margin-bottom: 0;
}

.title {
  font-weight: bold;
  font-size: 24px;
}

.introduction {
  font-size: 18px;
}

.centered {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.row-intro {
  padding-top: 5vh;
  padding-bottom: 2vh;
}

.row-middle {
  padding-bottom: 8vh;
}

.bold {
  text-shadow: 1px 0 white;
  letter-spacing: 1px;
  font-weight: 900;
}

.image-1882 {
  margin-left: 50px;
  width: 75%;
  max-height: 400px;
  max-width: 285px;
}

.image-tradition {
  max-height: 400px;
}

.image-roasting {
  max-height: 350px;
}

.image {
  border-radius: 6px;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  transition: transform 0.5s;
}

.image:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.small-stat {
  font-size: 0.7rem;
}

.stat-title {
  font-size: 24px;
  padding-bottom: 10vh;
}

.text {
  font-size: 16px;
}

.stat-line1 {
  padding-bottom: 8vh;
}

.line-height-null {
  line-height: 1;
}

.no-margin {
  margin: 0;
  padding: 0;
}

.quote {
  font-family: monospace;
  font-size: 24px;
}

.cta-shop {
  font-size: 18px;
}

.cta-shop-btn {
  border: 1px solid rgb(146, 86, 29);
  background-color: rgb(146, 86, 29);
  border-radius: 5px;
  color: white;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  transition-duration: 0.3s;
  line-height: 50px;
  height: 50px;
}

.cta-shop-btn:hover {
  position: relative;
  -moz-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  -o-transform: translate(0px, -3px);
  -webkit-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  transition-duration: 0.3s;
  cursor: pointer;
  box-shadow: 0 12px 24px -6px rgb(146, 86, 29);
  border: 1px solid rgb(146, 86, 29);
  background-color: white;
  color: rgb(146, 86, 29);
}

@media only screen and (max-width: 575px) {
  .roasting div:first-child {
    order: 1;
  }

  .no-margin {
    font-size: 1.3rem !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .no-margin {
    font-size: 1.6rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .offset-md-0 {
    margin-left: 8.33333%;
  }

  .no-margin {
    font-size: 1.6rem !important;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1250px) {
  .stat-title {
    padding-bottom: 6vh;
  }
}

@media only screen and (max-width: 959px) {
  .image-1882 {
    margin-left: 0;
    max-height: 30vh;
    width: auto;
  }

  .image-tradition {
    max-height: 30vh;
    width: auto;
  }

  .image-roasting {
    max-height: 30vh;
    width: auto;
  }

  .stat-title {
    font-size: 24px;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .stat-line1 {
    padding-bottom: 4vh;
  }

  .row-middle {
    padding-bottom: 4vh;
  }

  .no-margin {
    font-size: 2rem;
  }
}
</style>