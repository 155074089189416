<template>
  <div class="content">
    <div class="footer w-100">
      <div class="row vcenter">
        <div class="footer-logo-box-sm col-sm-6 offset-sm-3">
          La garantie d'un café exquis.
        </div>

        <div class="col-lg-4 col-md-4 col-sm-6">
          <h1>Site</h1>
          <ul>
            <li class="links">
              <a href="#home">Accueil</a>
            </li>
            <li class="links">
              <a href="#aboutus">L'entreprise</a>
            </li>
            <li class="links">
              <a href="#solutions">Nos solutions</a>
            </li>
            <li class="links">
              <a href="#vergnano">Caffè Vergnano</a>
            </li>
            <li class="links">
              <a href="#brands">Nos marques</a>
            </li>
          </ul>
        </div>

        <div class="footer-logo-box col-lg-4 col-md-4 col-sm-6">
          La garantie d'un café exquis.
        </div>

        <div class="col-lg-4 col-md-4 col-sm-6">
          <h1>Contact</h1>
          <p style="margin-bottom: 5px">
            <i class="icon fa fa-phone"></i>
            <a style="padding-bottom: 0px" href="tel:0471 68 99 66"
              >0471 68 99 66</a
            >
          </p>
          <span style="color: white; font-size: 14px; font-weight: bold"
            >&nbsp; &nbsp; &nbsp; &nbsp;ou</span
          >
          <p>
            <i class="icon fa fa-phone"></i>
            <a href="tel:0479 53 58 43">0479 53 58 43</a>
          </p>

          <p style="margin-bottom: 12px">
            <i class="icon fa fa-map-pin"></i>
            <a
              target="_blank"
              href="https://www.google.com/maps?q=Clos+des+princes+21,+7070+Le+Roeulx"
            >
              Clos des princes 21
              <br />&nbsp; &nbsp; &nbsp;
              <div style="margin-left: 25px">7070 Le Roeulx</div>
              <div style="margin-left: 30px">Belgique</div>
            </a>
          </p>

          <p style="margin-bottom: 12px">
            <i class="icon fa fa-envelope"></i>
            <a target="_blank" href="mailto:info@coffee-factory.be"
              >info@coffee-factory.be</a
            >
          </p>
        </div>
      </div>
      <div class="row vcenter">
        <div class="col">
          <div class="copyright">
            <span>Copyright © 2021 The Coffee Factory</span>
            <br />
            <span style="font-size: 8px">Icons by Icons8</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 960px) {
  .footer-logo-box-sm {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  .footer-logo {
    width: 75%;
  }

  .footer-logo-box {
    font-size: 24px;
    color: white;
  }

  .footer-logo-box::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 2px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 959px) {
  .footer-logo-box {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  .footer-logo-box-sm {
    display: block;
    opacity: 1;
    visibility: visible;
    font-size: 24px;
    color: white;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .footer-logo {
    width: 50%;
    padding-top: 24px;
  }

  .footer-logo-box-sm::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 2px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
  }
}

.vcenter {
  display: flex;
  align-items: center;
  text-align: center;
}

.icon {
  font-size: 24px;
  color: white;
  padding-right: 10px;
}

.footer h1 {
  font-size: 20px;
  line-height: 17px;
  padding: 20px 0px 20px 0px;
  color: white;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}

.footer ul {
  list-style-type: none;
}

.footer ul li,
.footer ul li a,
.footer p a,
.footer p {
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  text-decoration: none;
}

.footer ul li:hover,
.footer ul li a:hover,
.footer p a:hover,
.footer p:hover {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  text-decoration: none;
}

.copyright {
  color: white;
  font-size: 12px;
  line-height: 0.9;
}

.footer {
  background-image: url(../assets/banner-footer-840.jpg),
    url(../assets/banner-footer-360.jpg);
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  background-position: center, center;
  border-top: rgb(146, 86, 29) 2px solid;
}

@media screen and (min-width: 500px) {
  .footer {
    background-image: url(../assets/banner-footer-1024.jpg),
      url(../assets/banner-footer-840.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}

@media screen and (min-width: 840px) {
  .footer {
    background-image: url(../assets/banner-footer-1440.jpg),
      url(../assets/banner-footer-1024.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}

@media screen and (min-width: 1025px) {
  .footer {
    background-image: url(../assets/banner-footer-1920.jpg),
      url(../assets/banner-footer-1440.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}

@media screen and (min-width: 1441px) {
  .footer {
    background-image: url(../assets/banner-footer-3840.jpg),
      url(../assets/banner-footer-1920.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}
</style>