<template>
  <div class="content">
    <div class="row w-100 h-100 vcenter text-center home">
      <div class="row w-100">
        <div
          class="col-lg-6 offset-lg-6 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-8 offset-2"
        >
          <img class="logo" src="../assets/logo.png" />
        </div>
      </div>

      <div class="row w-100">
        <div
          class="col-lg-6 offset-lg-6 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-12"
        >
          <h4 class="p-0 m-0">La garantie d'un café exquis.</h4>
        </div>
      </div>

      <div class="row w-100">
        <div
          class="col-lg-3 offset-lg-6 col-md-3 offset-md-2 col-sm-4 offset-sm-2 col-6 offset-3 order-btn-wrapper"
        >
          <h6 class="order-btn w-100" @click="goToContact">Commander</h6>
        </div>

        <div
          class="col-lg-3 offset-lg-0 col-md-3 offset-md-0 col-sm-4 offset-sm-0 col-6 offset-3 discover-btn-wrapper"
        >
          <h6 class="discover-btn w-100" v-scroll-to="'#aboutus'">Découvrir</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToContact() {
      this.$router.push("contact");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "Quicksand", sans-serif;
}

.content {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  position: relative;
  background-image: url(../assets/home-1440.jpg), url(../assets/home-1024.jpg);
}

@media screen and (min-width: 1025px) {
  .content {
    background-image: url(../assets/home-1920.jpg), url(../assets/home-1440.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
  }
}

@media screen and (min-width: 1441px) {
  .content {
    background-image: url(../assets/home-3840.jpg), url(../assets/home-1920.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
  }
}

.row {
  margin: 0;
}

@media only screen and (min-width: 960px) {
  .logo {
    width: 75%;
  }
}

@media only screen and (min-width: 960px) and (max-width: 991px) {
  .order-btn-wrapper,
  .discover-btn-wrapper {
    flex: 0 0 33.333333%;
    max-width: 33.3333%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1263px) {
  .col-md-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 599px) {
  .col-12 {
    flex: 0 0 100%;
    margin-left: 0;
  }

  .order-btn-wrapper {
    margin-left: 25%;
  }

  .offset-sm-0 {
    margin-left: 25%;
  }
}

@media only screen and (min-width: 768px) {
  .order-btn {
    width: 80% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .discover-btn {
    width: 80% !important;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 601px) and (max-width: 991px) {
  .logo {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .content {
    background-image: url(../assets/home-rotate-1440.jpg),
      url(../assets/home-rotate-1024.jpg);
  }

  .home {
    padding-top: 38vh;
  }
}

@media only screen and (max-width: 840px) {
  .content {
    background-image: url(../assets/home-rotate-1024.jpg),
      url(../assets/home-rotate-840.jpg);
  }
}

@media only screen and (max-width: 500px) {
  .content {
    background-image: url(../assets/home-rotate-840.jpg),
      url(../assets/home-rotate-360.jpg);
  }
}

@media only screen and (min-width: 1500px) {
  h4 {
    font-size: 3rem !important;
  }

  .order-btn {
    border-radius: 5px !important;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    font-size: 26px !important;
  }

  .discover-btn {
    border-radius: 5px !important;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    font-size: 26px !important;
  }
}

h4 {
  font-weight: bold;
  font-size: 2rem;
}

@media only screen and (max-height: 600px) and (orientation: portrait) {
  h4 {
    font-size: 22px;
  }

  .logo {
    width: 100%;
  }

  .order-btn {
    border-radius: 5px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 14px !important;
  }

  .discover-btn {
    border-radius: 5px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 14px !important;
  }

  .col-12,
  .col-8,
  .col-6 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.vcenter {
  display: flex;
  align-items: center;
  align-content: space-evenly;
}

.order-btn {
  border: 1px solid rgb(146, 86, 29);
  background-color: rgb(146, 86, 29);
  border-radius: 5px;
  color: white;
  padding-top: 13px;
  padding-bottom: 13px;
  text-decoration: none;
  font-size: 18px;
  transition-duration: 0.3s;
  font-weight: bold;
}

.order-btn:hover {
  position: relative;
  -moz-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  -o-transform: translate(0px, -3px);
  -webkit-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  transition-duration: 0.3s;
  cursor: pointer;
  box-shadow: 0 12px 24px -6px rgb(146, 86, 29);
}

.discover-btn {
  border: 1px solid rgb(146, 86, 29);
  border-radius: 5px;
  color: black;
  padding-top: 13px;
  padding-bottom: 13px;
  text-decoration: none;
  font-size: 18px;
  transition-duration: 0.3s;
  font-weight: bold;
}

.discover-btn:hover {
  position: relative;
  -moz-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  -o-transform: translate(0px, -3px);
  -webkit-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  transition-duration: 0.3s;
  cursor: pointer;
  box-shadow: 0 12px 24px -6px rgb(146, 86, 29);
}
</style>