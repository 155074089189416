<template>
  <div class="content">
    <div class="row banner vcenter">
      <div class="col-12">
        <h2 class="banner-title">The Coffee Factory</h2>
        <br />
        <h3>Une large gamme de café de haute qualité</h3>
      </div>
    </div>

    <div class="cta-shop centered">
      <h6 class="cta-shop-btn" @click="goToContact">
        Acheter du café en ligne
      </h6>
    </div>

    <div class="row row-intro vcenter">
      <div class="col-md-10 offset-md-1 col-sm-10 offset-sm-1 col-10 offset-1">
        <p data-aos="fade-up" data-aos-duration="1000" class="introduction">
          Entreprise familiale depuis 2011, The Coffee Factory propose aux
          particuliers, mais également aux entreprises, aux restaurants et aux
          hôtels,
          <span class="bold">
            le meilleur du café à Bruxelles et en Région wallonne.
          </span>
        </p>
      </div>
    </div>

    <div class="row vcenter">
      <!-- Cup -->
      <div class="col-md-6 offset-md-0 col-sm-10 offset-sm-1 col-cup">
        <img id="cup" src="../assets/cup.png" />
      </div>

      <!-- Description -->
      <div class="col-md-6 offset-md-0 col-sm-10 offset-sm-1">
        <div class="col-md-10 offset-md-1 centered">
          <div data-aos="fade-left" data-aos-duration="1000">
            <h5 class="title">Le service</h5>
            <br />
            <p class="text">
              En tant qu’entreprise de petite taille, nous accordons beaucoup
              d’importance à vous fournir un service à taille humaine, basé sur
              des prestations personnalisées.
            </p>
            <br />
          </div>
          <div data-aos="fade-left" data-aos-duration="1000">
            <h5 class="title">La qualité</h5>
            <br />
            <p class="text">
              Importateur officiel de la marque italienne Caffè Vergnano, The
              Coffee Factory vous propose du café en grains, moulu, en capsules
              et en pads.
              <br />
              <br />Afin de faire ressortir au mieux ses arômes inimitables,
              nous fournissons également les machines à café les plus adaptées à
              votre situation, ainsi que tout un assortiment de produits
              complémentaires (sucre, lait, biscuits, chocolats, tasses,
              produits d’entretiens, etc.).
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 cta-contact centered">
      Vous désirez goûter notre café ?
      <h6 class="cta-contact-btn" @click="goToContact">
        Découvrez notre boutique
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "Entreprise",

  mounted() {
    if (screen.width > 959) {
      let rect = document.getElementById("cup").getBoundingClientRect();
      this.cupOffset = rect.top + window.scrollY / 2;
      document.getElementById("cup").style.transform =
        "translateX(" + "-" + this.cupOffset + "px)";

      this.cupWidth = document.getElementById("cup").clientWidth / 2;

      window.addEventListener("scroll", this.moveCup, { passive: true });
    }
  },

  data() {
    return {
      cupOffset: 0,
      cupWidth: 0,
    };
  },

  methods: {
    goToContact() {
      this.$router.push("contact");
    },

    goToShop() {
      this.$router.push("contact");
    },

    moveCup: function () {
      if (this.$router.currentRoute.name != "contact") {
        let offset = window.top.scrollY;

        document.getElementById("cup").style.transform =
          "translateX(" +
          "-" +
          (this.cupOffset - this.cupWidth - offset) +
          "px)";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vcenter {
  display: flex;
  align-items: center;
  text-align: center;
}

.row-intro {
  padding-top: 3vh;
}

.banner {
  color: white;
  padding-top: 16vh;
  padding-bottom: 11vh;
  text-shadow: 1px 1px 2px #111;
  background-color: transparent;
  background-image: url(../assets/banner-entreprise-840.jpg),
    url(../assets/banner-entreprise-360.jpg);
  background-repeat: no-repeat, no-repeat;
  background-position: center center;
  background-size: cover, cover;
  border-top: rgb(146, 86, 29) 2px solid;
  border-bottom: rgb(146, 86, 29) 2px solid;
}

.banner-title {
  font-weight: bold;
}

.cta-shop {
  margin-top: -25px;
  font-size: 18px;
}

.cta-shop-btn {
  border: 1px solid rgb(146, 86, 29);
  background-color: rgb(146, 86, 29);
  border-radius: 5px;
  color: white;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  transition-duration: 0.3s;
  line-height: 50px;
  height: 50px;
}

.cta-shop-btn:hover {
  position: relative;
  -moz-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  -o-transform: translate(0px, -3px);
  -webkit-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  transition-duration: 0.3s;
  cursor: pointer;
  box-shadow: 0 12px 24px -6px rgb(146, 86, 29);
  border: 1px solid rgb(146, 86, 29);
  background-color: white;
  color: rgb(146, 86, 29);
}

.cta-contact {
  font-size: 18px;
}

.cta-contact-btn {
  border: 1px solid rgb(146, 86, 29);
  background-color: rgb(146, 86, 29);
  border-radius: 5px;
  color: white;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  transition-duration: 0.3s;
  line-height: 50px;
  height: 50px;
}

.cta-contact-btn:hover {
  position: relative;
  -moz-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  -o-transform: translate(0px, -3px);
  -webkit-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  transition-duration: 0.3s;
  cursor: pointer;
  box-shadow: 0 12px 24px -6px rgb(146, 86, 29);
  border: 1px solid rgb(146, 86, 29);
  background-color: white;
  color: rgb(146, 86, 29);
}

.bold {
  color: #111;
  text-shadow: 1px 0 #111;
  letter-spacing: 1px;
  font-weight: bold;
}

.introduction {
  font-size: 18px;
}

.title {
  font-weight: bold;
  font-size: 24px;
}

.text {
  font-size: 16px;
}

#cup {
  width: 75%;
  height: auto;
  max-width: 500px;
  max-height: 500px;
}

.centered {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .offset-md-0 {
    margin-left: 8.33333%;
  }
}

@media only screen and (max-width: 959px) {
  .col-cup {
    height: 30vh;
  }

  #cup {
    text-align: center;
    width: auto;
    height: 100%;
  }
}

@media screen and (min-width: 500px) {
  .banner {
    background-image: url(../assets/banner-entreprise-1024.jpg),
      url(../assets/banner-entreprise-840.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}

@media screen and (min-width: 840px) {
  .banner {
    background-image: url(../assets/banner-entreprise-1440.jpg),
      url(../assets/banner-entreprise-1024.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}

@media screen and (min-width: 840px) {
}

@media screen and (min-width: 1025px) {
  .banner {
    background-image: url(../assets/banner-entreprise-1920.jpg),
      url(../assets/banner-entreprise-1440.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}

@media screen and (min-width: 1441px) {
  .banner {
    background-image: url(../assets/banner-entreprise-3840.jpg),
      url(../assets/banner-entreprise-1920.jpg);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    border-top: rgb(146, 86, 29) 2px solid;
  }
}
</style>