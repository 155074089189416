//Import styles
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "vuetify/dist/vuetify.min.css";
import 'sweetalert2/dist/sweetalert2.min.css';

//Import plugins
import Vue from 'vue';
import router from './router';
import Vuetify from 'vuetify';
import Scroll from 'vue-scrollto';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(Vuetify);
Vue.use(Scroll);
Vue.use(VueSweetalert2);

new Vue({
  el: '#app',
  router,
  vuetify: new Vuetify()
});
