import Vue from 'vue';
import Router from 'vue-router';

import App from '../views/App';
import Contact from '../views/Contact';
import NotFound from '../views/NotFound';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: App
        },
        {
            path: '/contact',
            name: 'contact',
            component: Contact
        },
        {
            path: '/shop',
            name: 'shop'
        },
        {
            path: "*",
            name: '404',
            component: NotFound
        }
    ],
});