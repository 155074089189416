<template>
  <div class="outer-container">
    <div class="inner-container">
      <div class="centered-content">
        <a id="about" href="/">
          <img class="logo" src="../assets/logo.png" />
        </a>
        <br />
        <br />
        <br />

        <h2>Il semblerait que cette page n'existe pas</h2>

        <br />
        <br />

        <h4>Cliquez <a href="/">ici</a> pour revenir à l'accueil</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.title = "404 Not Found";
    document
      .querySelector('meta[name="robots"]')
      .setAttribute("content", "noindex");
  },
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.outer-container {
  position: absolute;
  display: table;
  width: 100%;
  height: 100%;
  background: #ccc;
}

.inner-container {
  background-color: #f5f1e9;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.centered-content {
  display: inline-block;
  text-align: center;
  background: #fff;
  padding: 20px;
  border: 1px solid #000;
}

h1 {
  text-align: center;
}

.logo {
  width: auto;
  height: 70px;
}
</style>